import { Fragment, useCallback, useContext } from "react"
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SparkIcon } from "../../icons/circle.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as PinIcon } from "../../icons/pin2.svg";
import { ReactComponent as EyeIcon } from "../../icons/eye.svg";
import { ReactComponent as LinkIcon } from "../../icons/link-2.svg";
import { ReactComponent as EditIcon } from "../../icons/edit-2.svg";
import { ReactComponent as ShuffleIcon } from "../../icons/shuffle.svg";
import { ReactComponent as ConnectIcon } from "../../icons/connect.svg";
import { ReactComponent as PuffIcon } from "../../icons/puff.svg";
import { ReactComponent as ImageIcon } from "../../icons/imageIcon.svg";

import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as CancelIcon } from "../../icons/x.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";

import useHttp from "../../hooks/use-http.js";

import { deleteSpark, updateSpark, updateSparkImage } from "../../lib/api.js"
import AuthContext from "../../store/auth-context";
import SearchContext from "../../store/sparkSearch-context";
import MemberContext from "../../store/isMember-context";
import { textColourChoice } from "../../store/themesConfig";
import CardButtonWrapper from "../buttons/CardButtonWrapper";
import CentreAddWrapper from "../buttons/CentreAddWrapper";
import ImageUploadField from "../forms/fields/ImageUploadField.js";


const SparkContent = (props) => {

    const memberCtx = useContext(MemberContext);
    const authCtx = useContext(AuthContext);
    const userId = authCtx.id;

    const searchCtx = useContext(SearchContext);

    const theme = authCtx.theme;
    const themeBg = theme.bg;
    const themeCard = theme.card;
    const themePrimary = theme.highlight;
    const [textColour, setTextColour] = useState('')

    useEffect(() => {
        const textCol = textColourChoice(themeCard);
        setTextColour(textCol);
    }, [themeBg, setTextColour, themeCard]);

    // EDIT SPARK CONTROLS

    // control if connected spark is being edited or not

    const [isEditing, setIsEditing] = useState(false);

    // change state between editing and not editing

    const editHandler = () => {
        setIsEditing((prevState) => prevState ? false : true);
    };

    // Handle hovering on connect button (to display helper text)

    const [connButtonHover, setConnButtonHover] = useState(false);

    const showConnButton = () => {
        setConnButtonHover(true);
    };

    const hideConnButton = () => {
        setConnButtonHover(false);
    }

    // DATA IS SPARK CONTENT

    // spark content split should occur somewhere here

    const data = props.data;
    const navigate = useNavigate();

    const ownerId = data.owner.id;

    // const [curText, setCurText] = useState(data.text);
    const [curLink, setCurLink] = useState(data.link);
    const [isHeader, setIsHeader] = useState(data.header);

    // Handle hover state to display action buttons on spark
    const [inHoverState, setInHoverState] = useState(false);
    const isHovering = props.isHovering;

    // State which tracks the number of times a user has tapped a spark
    const [touchCount, setTouchCount] = useState(0);

    useEffect(() => {
        if (isHovering) {
            setInHoverState(true);
        } else {
            setInHoverState(false);
        }
    }, [isHovering])

    // this enables us to handle touch events without click events getting in the way
    // default behaviour is click event fires after any touch event
    const handleTouchEnd = (event) => {
        event.preventDefault();
    };

    // NEW HEADER FEATURE HERE
    // reference to current value of spark text in text form input field (link field needs to be added)

    const textHeaderRef = useRef();
    const textBodyRef = useRef();
    const sparkLinkRef = useRef();

    // these are the image state values for UPDATE SPARK FORM

    const [sparkImage, setSparkImage] = useState('');
    const [Preview, setPreview] = useState(data.image.image);
    const [imageLoaded, setImageLoaded] = useState(false)

    // this is the ACTUAL IMAGE of the spark - whatever goes in here either is (or is assumed) to be what is in db

    const [image, setImage] = useState(data.image.image);
    const [imagePlaceholder, setImagePlaceholder] = useState(data.image.placeholder);

    // Spark text data is auto split first. Text data display & editing purposes are handled separately
    let splitHeader
    let splitBody
    let onlyBody

    // Function which splits spark text by the first instance of the \n character, splitting header and body
    function splitByFirstNewLine(str) {
        const index = str.indexOf('\n');
        if (index !== -1) {
            const firstLine = str.substring(0, index);
            const remainingLines = str.substring(index + 1);
            return [firstLine, remainingLines];
        }
        return [str];
    }

    // Places header and body text into separate variables if header, otherwise takes text as is if body only
    if (isHeader) {
        const [firstLine, remainingLines] = splitByFirstNewLine(data.text);
        splitHeader = firstLine;
        splitBody = !remainingLines ? '' : remainingLines;
    } else {
        onlyBody = data.text;
    }

    // state for text in edit mode - this updates whenever we change the text in edit mode
    const [textHeader, setTextHeader] = useState(splitHeader);
    const [textBody, setTextBody] = useState(data.header ? splitBody : onlyBody);

    // state for text in non-edit mode - this only updates when we confirm update the spark in edit
    const [displayHeader, setDisplayHeader] = useState(data.header ? splitHeader : '');
    const [displayBod, setDisplayBod] = useState(data.header ? splitBody : onlyBody);

    // Updating state 
    const headerChangeHandler = (e) => { setTextHeader(textHeaderRef.current.value); };
    const bodyChangeHandler = (e) => { setTextBody(textBodyRef.current.value); };

    const keyPressHandler = (e) => {
        if (e.key === 'Enter') {
            textBodyRef.current.focus();
        };
        if (e.key === 'Backspace' && !textBody) textHeaderRef.current.focus();
    };

    useEffect(() => {
        if (isEditing) {
            if (isHeader) {
                if (textBody.length === 0) {
                    textHeaderRef.current.focus();
                }
            }
        }
    }, [isEditing, textHeaderRef, textBody, isHeader]);

    const { sendRequest, data: sparkData, error, status } = useHttp(updateSpark);
    const { sendRequest: sendPicRequest, data: picData, error: picError, status: picStatus } = useHttp(updateSparkImage);

    const updateSparkHandler = (spData) => {
        sendRequest(spData)
    }

    const updateContentHandler = (e) => {
        e.preventDefault();

        const text = textHeader ? textHeader.trimStart().trimEnd() + `\n` + textBody.trimStart().trimEnd() : textBody.trimStart().trimEnd();
        const link = sparkLinkRef.current.value;

        if (text.length === 0) return;

        setIsEditing(false);
        if (textHeader) {
            setIsHeader(true);
        } else {
            setIsHeader(false);
        }

        setCurLink(link);
        setDisplayHeader(textHeader);
        setDisplayBod(textBody);

        if (sparkImage) {
            setImage(Preview);
            setImagePlaceholder(Preview);
        };

        if (!Preview && data.image.image) {
            setImage(null);
            setImagePlaceholder(null);
        }

        updateSparkHandler({ data: { text: text, link: link, header: textHeader ? true : false }, id: data.id })
    }

    // This is the function to update the spark image

    const newImageHandler = useCallback((method) => {
        let formData = new FormData()
        formData.append('image', sparkImage)
        sendPicRequest({
            id: data.image.id,
            method: method,
            data: formData,
        })
    }, [sparkImage, sendPicRequest, data])

    // Once spark is updated update image data

    useEffect(() => {
        if (status === 'completed' && !error && (sparkImage || (!Preview && data.image.image))) {
            if (sparkImage) {
                newImageHandler('PATCH')
            }
            if (!Preview && data.image.image) {
                newImageHandler('DELETE')
            }
        }
    }, [status, error, sparkImage, newImageHandler, Preview, data])

    // CONTROLS FOR UPDATING PINNED / PUBLIC STATES

    const [isPublic, setIsPublic] = useState(data.public);
    const [isPinned, setIsPinned] = useState(data.pinned);

    const pinnedHandler = () => {
        setIsPinned((prevState) => prevState ? false : true)
        updateSpark({ data: { pinned: isPinned ? false : true }, id: data.id })
    }

    const publicHandler = () => {
        setIsPublic((prevState) => prevState ? false : true)
        updateSpark({ data: { public: isPublic ? false : true }, id: data.id })
    }

    // CONTROLS FOR RESETTING PAGE TO 1 WHEN MOVING BETWEEN DIFFERENT SPARK DETAIL PAGES

    let pageReset;

    if (props.type === 'surSpark') {
        pageReset = props.pageReset;
    }

    // Handles clicking plus or check on centre spark - toggles connecting state and resets input if back to non-connecting state
    const connectingHandler = () => {
        if (props.conSparkState.state === true) {
            props.conSparkState.resetInput();
        }
        props.conSparkState.handler();
        props.pageReset();
    }

    // NAVIGATION FUNCTION WHEN SURROUNDING SPARK IS CLICKED TO BE CENTRED

    function sparkDetailHandler() {
        navigate(`/${props.collHandle}/spark/${props.data.id}`);
        searchCtx.function('');
        if (props.type === 'surSpark') {
            pageReset();
            props.conSparkState.removeConState();
            props.conSparkState.resetInput();
        }
    }

    // this function tracks how many times a user has tapped
    // if this is the first tap, then onhover action buttons appear
    // if it is the second tap, then it will centre that spark if it isn't currently centred
    // if the spark is centred, then it will reset the count and hide the buttons
    const updateHoverOnTap = () => {
        setTouchCount(touchCount + 1)
        if (touchCount === 0) {
            setInHoverState(true)
        }
        if (touchCount === 1) {
            if (props.type !== 'centre') {
                sparkDetailHandler()
            } else {
                setInHoverState(false);
                setTouchCount(0);
            }
        }
    }

    const deleteThisSpark = () => {
        if (props.type === 'centre') {
            navigate(`/${props.collHandle}`);
            deleteSpark(props.data.id)
        } else {
            deleteSpark(props.data.id)
            const curResults = props.onResultsUpdate.state;
            const updateResults = props.onResultsUpdate.function;
            let newResults = curResults.filter((item) => item.id !== props.data.id);
            updateResults(newResults);
        }
    };

    const [displayImg, setDisplayImg] = useState(false);

    const showImg = () => {
        setDisplayImg(true)
    }

    const hideImg = () => {
        setDisplayImg(false)
    }

    const toggleImg = () => {
        setDisplayImg(!displayImg)
    }

    const linkBorder = props.type === 'centre' ? themePrimary : textColour;
    const centreLinkStyle = "absolute -bottom-1 -right-1 transition duration-75 ease-in-out bg-white hover:scale-105 border rounded-full p-2 cursor-pointer";
    const surLinkStyle = `absolute -bottom-1 -right-1 transition duration-75 ease-in-out bg-white hover:scale-105 border rounded-full p-2 cursor-pointer`;
    const txtAreaStyle = "w-full z-10 h-full absolute top-0 left-0 right-0 bottom-0 resize-none overflow-hidden outline-none bg-transparent "
    const contentStyle = {
        header: txtAreaStyle + "font-light text-lg md:text-xl leading-snug",
        body: txtAreaStyle + "font-elight text-sm md:text-base leading-snug"
    };

    const imgContainerShowStyle = 'absolute top-0 left-0 bottom-0 right-0 overflow-hidden rounded-md flex place-content-center'
    const imgContainerDefaultStyle = 'absolute top-0 left-0 bottom-0 right-0 overflow-hidden rounded-md flex place-content-center opacity-25'
    const imgDefaultStyle = 'object-cover rounded-md w-full h-full z-10'
    const imgShowStyle = 'object-contain rounded-md w-full h-full z-10'

    if (!isEditing) {
        return (
            <Fragment>
                {image && (
                    <div className={(displayImg) ? imgContainerShowStyle : imgContainerDefaultStyle}>
                        <img onLoad={() => setImageLoaded(true)} style={imageLoaded ? {} : { display: 'none' }} alt={data.text} className={(displayImg) ? imgShowStyle : imgDefaultStyle} src={image}></img>
                        <img alt={data.text} className="absolute w-full h-full top-0 left-0 object-cover rounded-md blur-md" src={imagePlaceholder}></img>
                    </div>
                )}
                <div className="absolute top-1 left-1 gap-1 flex flex-row">
                    <SparkIcon className="h-5 w-5 opacity-60" />
                    {(picStatus !== 'pending' && !picError && image) && (
                        <ImageIcon onClick={toggleImg} onMouseEnter={showImg} onMouseLeave={hideImg} className="z-20 h-5 w-5 opacity-60 hover:opacity-90 transition-all" />
                    )}
                    {(picStatus === 'pending' && !picError && sparkImage) && (
                        <PuffIcon className="h-5 w-5" />
                    )}
                    {(picError) && (
                        <div className="opacity-60 hover:opacity-80 transition-all px-1 flex flex-row gap-1 items-center justify-center w-full bg-red-700 text-white rounded-lg">
                            <p className="font-elight text-xs">Upload failed</p>
                            <ImageIcon className="h-4 w-4" />
                        </div>
                    )}
                </div>
                <div className="absolute top-1.5 right-1.5 space-x-2 opacity-60">
                    {(isPublic && memberCtx.state) && (
                        <div className="inline space-x-0.5">
                            <EyeIcon className="inline align-top h-4 w-4 " />
                        </div>
                    )}
                    {isPinned && (
                        <div className="inline space-x-0.5">
                            <PinIcon className="inline align-top h-4 w-4 " />
                        </div>
                    )}
                    <div className="inline space-x-0.5">
                        <ConnectIcon className="inline align-top h-4 w-4 " />
                        {props.type === 'surSpark' && (
                            <div className="inline align-top font-light text-sm">{data.connected_count.length}</div>
                        )}
                        {props.type !== 'surSpark' && (
                            <div className="inline align-top font-light text-sm">{data.connected.length}</div>
                        )}
                    </div>
                </div>
                {/* New header feature here */}
                <div style={{ 'color': `${textColour}` }} onTouchEnd={handleTouchEnd} onTouchStart={updateHoverOnTap} onClick={props.type !== 'centre' ? sparkDetailHandler : updateHoverOnTap} className={`z-10 w-full cursor-pointer mt-9 ${props.type === 'centre' ? 'mb-9' : 'mb-4'} px-2 sm:px-3 font-elight text-darkgrey text-base leading-snug overflow-auto overscroll-contain`}>
                    {(!displayImg) && (
                        <Fragment>
                            {isHeader && (
                                <Fragment>
                                    {splitHeader !== undefined && (<div className="font-light text-lg md:text-xl whitespace-pre-line">{displayHeader}</div>)}
                                    {splitBody !== undefined && (<div className="font-elight mt-1 text-sm md:text-base whitespace-pre-line">{displayBod}</div>)}
                                </Fragment>
                            )}
                            {!isHeader && (
                                <div className="font-elight mt-1 text-sm sm:text-base whitespace-pre-line">{displayBod}</div>
                            )}
                        </Fragment>
                    )}
                </div>
                {props.type === 'centre' && (
                    <div style={{ 'borderColor': `${linkBorder}`, 'backgroundColor': `${themeCard}` }} title={`Shuffle`} onClick={props.onRandomise} className="absolute -bottom-1 -left-1 transition duration-75 ease-in-out border hover:scale-105 rounded-full p-2 cursor-pointer">
                        <ShuffleIcon className="h-3 w-3" />
                    </div>
                )}
                {(inHoverState && !displayImg) && (
                    <Fragment>
                        {memberCtx.state && (
                            <div className="absolute flex flex-row -top-2 space-x-1">
                                <CardButtonWrapper type={'action'} hoverTitle={isPublic ? `Make Private` : `Make Public`} clickAction={publicHandler}>
                                    <EyeIcon className="h-5 w-5 stroke-inherit" />
                                </CardButtonWrapper>
                                {userId == ownerId && (
                                    <CardButtonWrapper type={'action'} hoverTitle={`Edit`} clickAction={editHandler}>
                                        <EditIcon className="h-5 w-5 stroke-inherit" />
                                    </CardButtonWrapper>
                                )}
                                <CardButtonWrapper type={'action'} hoverTitle={`Pin`} clickAction={pinnedHandler}>
                                    <PinIcon className="h-5 w-5" />
                                </CardButtonWrapper>
                            </div>
                        )}
                    </Fragment>
                )}
                <div className="absolute flex flex-row -bottom-2 left-50% space-x-1">
                    {(props.type === 'centre' && memberCtx.state) && (
                        <Fragment>
                            <CentreAddWrapper type={'add'} hoverTitle={!props.conSparkState.state ? `Connect a Spark` : `Finish Connecting`} clickAction={() => connectingHandler()}>
                                {!props.conSparkState.state && (
                                    <PlusIcon className={`w-11 h-11 p-2 rounded-full stroke-inherit`} />
                                )}
                                {props.conSparkState.state && (
                                    // <CheckIcon className="w-11 h-11 p-2 rounded-full stroke-inherit" />
                                    <div className="p-[0.6rem] w-11 h-11 rounded-full font-light text-center">Ok</div>
                                )}
                            </CentreAddWrapper>
                        </Fragment>
                    )}
                    {((inHoverState && !displayImg) || (props.type === 'surSpark' ? props.conSparkState.state : false)) && (
                        <Fragment>
                            {(props.type === 'surSpark' && memberCtx.state) && (
                                <Fragment>
                                    {!props.isConnected && (
                                        <CentreAddWrapper type={'connect'} hoverTitle={`Connect to Centre Spark`} hoverOverFunction={showConnButton} hoverOutFunction={hideConnButton} clickAction={props.submitConnectHandler}>
                                            <ConnectIcon className="h-5 w-5" />
                                            {connButtonHover && (
                                                <p className="text-sm text-inherit font-light ml-1">Connect</p>
                                            )}
                                        </CentreAddWrapper>
                                    )}
                                    {props.isConnected && (
                                        <CentreAddWrapper type={'disconnect'} hoverTitle={`Disonnect from Centre Spark`} hoverOverFunction={showConnButton} hoverOutFunction={hideConnButton} clickAction={props.submitConnectHandler}>
                                            <ConnectIcon className="h-5 w-5" />
                                            {connButtonHover && (
                                                <p className="text-sm text-inherit font-light ml-1">Disonnect</p>
                                            )}
                                        </CentreAddWrapper>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
                {curLink && (
                    <a href={curLink} target="_blank" rel="noreferrer">
                        <div style={{ 'borderColor': `${linkBorder}`, 'backgroundColor': `${themeCard}` }} title={`${curLink}`} className={props.type === 'centre' ? centreLinkStyle : surLinkStyle}>
                            <LinkIcon className={`h-4 w-4`} />
                        </div>
                    </a>
                )}
            </Fragment>
        )
    }

    if (isEditing) {
        return (
            <div key={data.id} className="w-full h-full flex place-content-center">
                <div className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden opacity-25 rounded-md flex place-content-center">
                    <img className="object-cover rounded-md" src={Preview ? Preview : ""}></img>
                    <img className="-z-10 absolute w-full h-full top-0 left-0 object-cover rounded-md blur-md" src={Preview ? Preview : ""}></img>
                </div>
                <div className="absolute top-1 left-1 gap-1 flex flex-row">
                    <SparkIcon className="h-5 w-5 opacity-60" />
                    <ImageUploadField Preview={Preview} setSparkImage={setSparkImage} setPreview={setPreview} />
                </div>
                <div className="absolute top-1.5 right-1.5 space-x-2 opacity-60">
                    {isPublic && (
                        <div className="inline space-x-0.5">
                            <EyeIcon className="inline align-top h-4 w-4" />
                        </div>
                    )}
                    {isPinned && (
                        <div className="inline space-x-0.5">
                            <PinIcon className="inline align-top h-4 w-4" />
                        </div>
                    )}
                    {/* {data.link.length > 0 && (
                        <div className="inline space-x-0.5">
                            <LinkIcon className="inline align-top h-4 w-4 stroke-darkgrey" />
                        </div>
                    )} */}
                    <div className="inline space-x-0.5">
                        <ConnectIcon className="inline align-top h-4 w-4" />
                        {props.type === 'surSpark' && (
                            <div className="inline align-top font-elight text-sm">{data.connected_count.length}</div>
                        )}
                        {props.type !== 'surSpark' && (
                            <div className="inline align-top font-elight text-sm">{data.connected.length}</div>
                        )}
                    </div>
                </div>
                {/* New header feature here*/}
                <div style={{ 'color': `${textColour}` }} className="mt-9 mb-9 relative flex flex-col w-full overflow-auto">
                    <div className="relative mx-2 md:mx-3">
                        <textarea
                            id="headText"
                            className={contentStyle.header}
                            value={textHeader}
                            placeholder="Type your spark here..."
                            maxLength="500"
                            ref={textHeaderRef}
                            onChange={headerChangeHandler}
                            // onFocus={addAutoResize}
                            onKeyDown={(e) => keyPressHandler(e)}
                            autoFocus
                        />
                        <div className="opacity-0 pb-1 font-light text-lg md:text-xl leading-snug whitespace-pre-line">{(!textHeader || textHeader.length === 0) ? 'Type your spark here...' : textHeader}</div>
                    </div>
                    <div className="relative mx-2 md:mx-3">
                        <textarea
                            id="bodText"
                            className={contentStyle.body}
                            placeholder="Add more detail here..."
                            maxLength="500"
                            ref={textBodyRef}
                            onChange={bodyChangeHandler}
                            // onFocus={addAutoResize}
                            onKeyDown={(e) => keyPressHandler(e)}
                            value={textBody}
                            autoFocus
                        />
                        <div className="opacity-0 pb-1 font-elight text-sm md:text-base leading-snug whitespace-pre-line">{!textBody || textBody.length === 0 ? 'Add more detail here...' : textBody}</div>
                    </div>
                </div>
                <div className="absolute bottom-2 w-full">
                    <input
                        className="ml-2 md:ml-4 w-7/12 md:w-9/12 bottom-2 pt-2 outline-none bg-transparent font-elight text-[0.7rem] md:text-base leading-snug overflow-auto overscroll-contain"
                        placeholder="Enter a link..."
                        defaultValue={curLink}
                        ref={sparkLinkRef}
                    />
                </div>
                {
                    (userId == ownerId) && (
                        <Fragment>
                            <div className="absolute flex flex-row -top-3 left-50% space-x-1">
                                {isEditing && (
                                    <CardButtonWrapper type={'edit'} hoverTitle={`Delete`} clickAction={deleteThisSpark}>
                                        <TrashIcon className="h-5 w-5" />
                                    </CardButtonWrapper>
                                )}
                                <CardButtonWrapper type={'edit'} hoverTitle={`Cancel`} clickAction={editHandler}>
                                    <CancelIcon className="h-5 w-5" />
                                </CardButtonWrapper>
                                {/* <div title={`Submit`} onClick={updateContentHandler} className="bg-white border border-salmon rounded-full p-2 cursor-pointer">
                                <CheckIcon className="h-5 w-5 stroke-darkgrey" />
                            </div> */}
                            </div>
                            <div className="absolute flex flex-row bottom-1 right-1 left-50% space-x-1">
                                <CardButtonWrapper type={'submit'} hoverTitle={`Submit`} clickAction={updateContentHandler}>
                                    <CheckIcon className="h-5 w-5 md:w-9" />
                                </CardButtonWrapper>
                            </div>
                        </Fragment>
                    )
                }
            </div >
        )
    }
}

export default SparkContent;